import socialIcon0 from "../Assets/social_icon1.png"
import socialIcon1 from "../Assets/social_icon2.png"
import socialIcon2 from "../Assets/social_icon3.png"
import socialIcon3 from "../Assets/social_icon4.png"
import socialIcon4 from "../Assets/social_icon5.png"
import socialIcon5 from "../Assets/social_icon6.png"
import socialIcon6 from "../Assets/social_icon7.png"

export default function R76Footer() {
  return (
    <div
      className="nunito-sans-400"
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1901 / 301",
        backgroundColor: "black",
        color: "white",
        fontWeight: "700"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0%",
          aspectRatio: "1901 / 301",
          justifyContent: "start",
          alignItems: "stretch",
          padding: "3.6296685954760655% 0% 0.14834297386863746% 0%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 33",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2.0515518148342977%",
              aspectRatio: "1901 / 33",
              justifyContent: "center",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "26 / 32",
                backgroundImage: "url('"+socialIcon0+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "26 / 32",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 23.076923076923077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "26 / 26",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "26 / 26",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "26 / 32",
                backgroundImage: "url('"+socialIcon1+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "26 / 32",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 23.076923076923077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "26 / 26",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "26 / 26",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "26 / 32",
                backgroundImage: "url('"+socialIcon2+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "26 / 32",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 23.076923076923077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "26 / 26",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "26 / 26",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "26 / 32",
                backgroundImage: "url('"+socialIcon3+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "26 / 32",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 23.076923076923077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "26 / 26",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "26 / 26",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "23 / 33",
                backgroundImage: "url('"+socialIcon4+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "23 / 33",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 26.08695652173913% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "23 / 27",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "23 / 27",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "26 / 32",
                backgroundImage: "url('"+socialIcon5+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "26 / 32",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 23.076923076923077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "26 / 26",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "26 / 26",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "26 / 32",
                backgroundImage: "url('"+socialIcon6+"')",
                backgroundSize:"contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "26 / 32",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 23.076923076923077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "26 / 26",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0%",
                      aspectRatio: "26 / 26",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 0% 0% 0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 43",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "1901 / 43",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 21.170000076293945",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2.472382956338769%",
              aspectRatio: "1901 / 21.170000076293945",
              justifyContent: "center",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "76.55999755859375 / 21.170000076293945",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0%",
                  aspectRatio: "76.55999755859375 / 21.170000076293945",
                  justifyContent: "center",
                  alignItems: "stretch",
                  padding: "0% 0% 0.06530726052111077% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14.987500190734863px",
                    lineHeight: "21.1200008392334px",
                  }}
                >
                  SK Square
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "153.69000244140625 / 21.170000076293945",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0%",
                  aspectRatio: "153.69000244140625 / 21.170000076293945",
                  justifyContent: "center",
                  alignItems: "stretch",
                  padding: "0% 0% 0.03253252408503858% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15.8125px",
                    lineHeight: "21.1200008392334px",
                  }}
                >
                  Comcast Spectacor
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "2048.080078125 / 23.829999923706055",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "2048.080078125 / 23.829999923706055",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 1",
           
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5260389268805892%",
              aspectRatio: "1901 / 1",
              justifyContent: "center",
         
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "636.5 / 1",
                borderBottom: "1px solid white",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "636.5 / 1",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 23.829999923706055",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "1901 / 23.829999923706055",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 23",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "1901 / 23",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 18",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0%",
              aspectRatio: "1901 / 18",
              justifyContent: "center",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
              textDecoration: "underline",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "13.16249942779541px",
                lineHeight: "17.280000686645508px",
              }}
            >
              El equipo
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "75.16000366210938 / 18",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "75.16000366210938 / 18",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "12.824999809265137px",
                lineHeight: "17.280000686645508px",
              }}
            >
              Careers
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "67.1500015258789 / 18",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "67.1500015258789 / 18",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "12.262499809265137px",
                lineHeight: "17.280000686645508px",
              }}
            >
              Legal Terms
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "66.12999725341797 / 18",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "66.12999725341797 / 18",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "13.16249942779541px",
                lineHeight: "17.280000686645508px",
              }}
            >
              Contact
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "60.369998931884766 / 18",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "60.369998931884766 / 18",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "12.375px",
                lineHeight: "17.280000686645508px",
              }}
            >
              Privacy Policy
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "43.9900016784668 / 18",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "43.9900016784668 / 18",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "12.59999942779541px",
                lineHeight: "17.280000686645508px",
              }}
            >
              Cookie Policy
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 49",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "1901 / 49",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1901 / 17.200000762939453",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5260389268805892%",
              aspectRatio: "1901 / 17.200000762939453",
              justifyContent: "center",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                fontSize: "14.646875381469727px",
                lineHeight: "17.200000762939453px",
              }}
            >
              COPYRIGHT @ 2024 SK Telecom CS T1 Co. ALL RIGHTS RESERVERED
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
