import "./App.css";
import { Fragment, useEffect, lazy, useRef, useState, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import BlogReadingPage from "./Blog";

import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Scrollbar from "smooth-scrollbar";

const R76AppBar = lazy(() => import("./R76AppBar"));
const R76LogoCarpet = lazy(() => import("./Home/R76LogoCarpet"));
const R76Footer = lazy(() => import("./Home/R76Footer"));
const Home = lazy(() => import("./Home"));
const Comunidad = lazy(() => import("./Comunidad/R76Teams"));
const R76 = lazy(() => import("./R76/R76"));
const TrustComponent = lazy(() => import("./TrustComponent"));
const Modal = lazy(() => import("./Modal"));

// typical import

// get other plugins:

// 'https://images.pexels.com/photos/7915245/pexels-photo-7915245.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.25',
// "https://images.pexels.com/photos/7915285/pexels-photo-7915285.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.25",
// "https://images.pexels.com/photos/7915289/pexels-photo-7915289.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.25",
// "https://images.pexels.com/photos/7915280/pexels-photo-7915280.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.25"
function App() {
  const containerRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  function adjustFontSize() {
    const rootFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    const rate = 0.9856710393541879;
    document.querySelectorAll("div").forEach((el) => {
      console.log(el.style.fontSize.replace("px", ""));
      if (el.style.fontSize && el.style.fontSize.replace("px", "") !== "") {
        el.style.fontSize = rate * el.style.fontSize.replace("px", "") + "px"; // Example scaling factor
        // Example scaling factor
      }
      if (el.style.lineHeight && el.style.lineHeight.replace("px", "") !== "") {
        el.style.lineHeight =
          rate * el.style.lineHeight.replace("px", "") + "px";
      }
      if (
        el.style.letterSpacing &&
        el.style.letterSpacing.replace("px", "") !== ""
      ) {
        el.style.letterSpacing =
          rate * el.style.letterSpacing.replace("px", "") + "px";
      }
    });
  }

  const location = useLocation();

  useEffect(() => {
    // gsap.to('body', { duration: 0.5, opacity: 0, onComplete: () => {
    //   gsap.to('body', { duration: 0.5, opacity: 1 });
    // }});
  }, [location]);


  useEffect(() => {
    adjustFontSize();

    // gsap.registerPlugin(ScrollTrigger);

    // // const rootDiv = document.querySelector("#root");
    // const scrollbar = Scrollbar.init(containerRef.current, {
    //   damping: 0.04 * 0.64, // Adjust damping to control smoothness
    // });

    // // ScrollTrigger.scrollerProxy(containerRef.current, {
    // //   scrollTop(value) {
    // //     if (arguments.length) {
    // //       scrollbar.scrollTop = value;
    // //     }
    // //     return scrollbar.scrollTop;
    // //   },
    // // });

    // scrollbar.addListener(ScrollTrigger.update);

    // ScrollTrigger.defaults({
    //   scroller: containerRef.current,
    //   markers: false, // Disable markers if not needed
    //   start: "top top",
    //   end: "bottom bottom",
    // });

    // gsap.to(".container", {
    //   scrollTrigger: {
    //     trigger: ".container",
    //     start: "top top",
    //     end: "bottom bottom",
    //     scrub: true,
    //   },
    // });

    return () => {
      // if (scrollbar) scrollbar.destroy();
      // ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  // return <Home />;
  return (
    <Fragment>
      <Suspense>
        {showModal && (
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            ref={modalRef}
          />
        )}
        <Suspense></Suspense>

        <R76AppBar />
      </Suspense>
      <div
        ref={containerRef}
        style={{ maxHeight: "100vh", height: "100vh", overflow: "hidden" }}
      >
          <Suspense>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="comunidad" element={<Comunidad />} />
                <Route path="R76" element={<R76 />} />
                <Route path="reading" element={<BlogReadingPage />} />
              </Route>
            </Routes>
          </Suspense>
      </div>
      <Suspense>
        <TrustComponent
          openModal={handleOpenModal}
          closeModal={handleCloseModal}
        />
      </Suspense>
    </Fragment>
  );
}

export default App;
