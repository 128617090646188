import React, { Fragment, Suspense, useEffect, useState } from "react";
import { VariableSizeList as List } from "react-window";
import R76LogoCarpet from "../Home/R76LogoCarpet";
import R76Footer from "../Home/R76Footer";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";

// const HeavyLand = React.lazy(() => import('../Assets/heavyland0.png'));


export default function BlogReadingPage() {
    
  useEffect(() => {
    let x = document.querySelector(".home-page > div");
    const tl = gsap.timeline();
    tl.set("body", { backgroundColor: "#ECE8E1" });
    tl.set(x, { y: "80", opacity: 0 });
    tl.set(".home-page", { opacity: 1 });
    tl.to(x, {
      y: "0",
      opacity: 1,
      //   scale: 1,
      duration: 0.357 * 1.84,
      ease: "power2.easeOut",
    });
  }, []);
  // var [imgs] = useState([
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2F9e18b0dcdd9195f18dfb906dae078aae43c76de8-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2Ffdc0dc1f5336a738131c74e2708bb87da8e322b3-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2Fa2889bdcb93e60139fec19f8d8b9e4a7905728cf-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2Fd076dbf1e5b26cc65ce418739e21b815437fa86a-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2Ff4af91202c2e9394fcbf49875a8eda7d5fca397a-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2F12d353ac05829eb8f278f7a03b972aff00de202b-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2F8f25e685216d18f2369948177934cf0e68396531-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2Fba8702355659399a14ed8aa0a44d6c78b2eb4fa7-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2F37b2c203326c5916faee6f89ab4c6b96ed4f7b84-2560x2560.png&w=828&q=75",
  //     "https://merch.riotgames.com/_next/image/?url=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Fdsfx7636%2Fconsumer_products%2F43acb69f76f6310419c16b79fec2a0ac7cacdf4e-2560x2560.png&w=828&q=75",
  //   ]);

  //   var [names] = useState([
  //     "VALORANT Champions 2024 // Supernova Dry Fit Long Sleeve Tee",
  //     "VALORANT Champions 2024 // Supernova Packable Anorak",
  //     "VALORANT Champions 2024 // Supernova Tee",
  //     "Tactibear Large Plush",
  //     "Dan The Penguin Large Plush",
  //     "Anima Squad Primordian Aatrox Tee",
  //     "Teamfight Tactics 5 Year Bash Tee",
  //     "Teamfight Tactics 5 Year Bash Hat",
  //     "Poggles Plush",
  //     "Whisker Plush",
  //   ]);

  const [sizes] = useState({
    home: [
      //   1901 / 83.64,
      1901 / 1094.19,
      1901 / 596,
      1901 / 518,
      1901 / 190,
      1901 / 98,
      1901 / 873.5,
      1901 / 99.52,
      1901 / 301,
    ],
  });
  useEffect(() => {
    // const handleResize = () => {
    //   setWindowWidth(window.innerWidth);
    // };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const getItemSize = (index, windowWidth) => {
    return windowWidth / sizes["home"][index];
  };

  const [homeElements] = useState([
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1901 / 1094.18994140625",
      }}
    >
      <Suspense>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.9139181070471208%",
            aspectRatio: "1901 / 1094.18994140625",
            justifyContent: "start",
            alignItems: "stretch",
            padding:
              "7.006312595550039% 18.88479747501315% 3.3666491320357705% 18.88479747501315%",
            // backgroundImage: 'url("https://lh3.google.com/u/1/d/1rD3FJNBgnSZDNOzskVLl-5Md0-T88Kvp=s320-w320-h200-k-p")',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              aspectRatio: "1183 / 897",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5.351170568561873%",
                aspectRatio: "1183 / 897",
                justifyContent: "start",
                alignItems: "stretch",
                padding: "0% 0% 0% 0%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  aspectRatio: "1183 / 666",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0%",
                    aspectRatio: "1183 / 666",
                    justifyContent: "start",
                    alignItems: "stretch",
                    padding: "0% 0% 0% 0%",
                    backgroundImage:
                      'url("https://cmsassets.rgpub.io/sanity/images/dsfx7636/news/0959459750f1b5ab6beef3c8a071fda137886c01-1920x1080.jpg?auto=format&fit=fill&q=80&w=1184")',
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  aspectRatio: "1183 / 183",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8.743169398907105%",
                    aspectRatio: "1183 / 183",
                    justifyContent: "start",
                    alignItems: "stretch",
                    padding: "0% 0% 0% 0%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      aspectRatio: "1183 / 65",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.84530853761623%",
                        aspectRatio: "1183 / 65",
                        justifyContent: "center",
                        alignItems: "stretch",
                        padding: "0% 0% 0% 0%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "35.375px",
                          lineHeight: "65px",
                          fontWeight: "900",
                        }}
                      >
                        DESAFÍOS PARA LA COMUNIDAD DE VALORANT // 2023
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      aspectRatio: "1183 / 102",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "23.52941176470588%",
                        aspectRatio: "1183 / 102",
                        justifyContent: "start",
                        alignItems: "stretch",
                        padding: "0% 0% 0% 0%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "16.3125px",
                          lineHeight: "28px",
                          fontWeight: "500",
                        }}
                      >
                        Despedimos el 2023, pero tenemos un desafío más para
                        ustedes.
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          aspectRatio: "1183 / 4",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0%",
                            aspectRatio: "1183 / 4",
                            justifyContent: "start",
                            alignItems: "stretch",
                            padding: "0% 0% 0% 0%",
                            backgroundColor: "#BCB9B4",
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          aspectRatio: "1183 / 22",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.6762468300929839%",
                            aspectRatio: "1183 / 22",
                            justifyContent: "center",
                            alignItems: "stretch",
                            padding: "0% 0% 0% 0%",
                            fontWeight: "500",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              fontSize: "12.578125px",
                              lineHeight: "22px",
                              color: "#FF4655",
                            }}
                          >
                            Comunidad
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              fontSize: "14px",
                              lineHeight: "22px",
                            }}
                          >
                            |
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              fontSize: "12.578125px",
                              lineHeight: "22px",
                            }}
                          >
                            hace 8 meses
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </div>,
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1902 / 596",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5.369127516778524%",
          aspectRatio: "1902 / 596",
          justifyContent: "start",
          alignItems: "stretch",
          padding:
            "2.5236593059936907% 27.287066246056785% 1.6824395373291272% 27.287066246056785%",
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.3125px",
            lineHeight: "28px",
          }}
        >
          Si algo nos ha enseñado el 2023 es que la comunidad de VALORANT es una
          fuerza a tener en cuenta. Cada celebración ha sido una experiencia
          emocionante, desde las presentaciones de los nuevos agentes, la
          apasionante competencia en el nuevo mapa Sunset o el camino hacia el
          VCT Champions 2023 y todos los aficionados que asistieron a los
          eventos. Nos quedan pocas semanas para el 2024, así que nos gustaría
          dedicar un momento para celebrar con ustedes: los asesinos que cada
          día llevan a sus equipos a la victoria en Premier, los cosplayers, los
          artistas de la comunidad y los creadores en todo el mundo que
          enaltecen a VALORANT y a la comunidad todos los días.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.3125px",
            lineHeight: "28px",
          }}
        >
          Aunque nos han sorprendido con su asistencia a los eventos y sus
          esfuerzos en el juego, les preparamos una última cosa. Un pequeño
          desafío, por así decirlo, antes de avanzar al 2024.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.171875px",
            lineHeight: "28px",
            fontWeight: "700",
          }}
        >
          Presentación de los Desafíos para la Comunidad
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.171875px",
            lineHeight: "28px",
          }}
        >
          Durante las tres semanas siguientes, lanzaremos desafíos centrados en
          una serie de modos, estilos de juego y contribuciones generales al
          éxito de tu equipo. Claro, todos trabajarán juntos para alcanzar
          ciertas metas, pero con una ligera competencia amistosa entre ustedes
          durante el camino. Una vez que la comunidad y tú hayan completado el
          desafío, les compartiremos un código que pueden canjear en línea por
          unas cuantas recompensas divertidas de fin de año.
        </div>
      </div>
    </div>,
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1902 / 518",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "6.177606177606178%",
          aspectRatio: "1902 / 518",
          justifyContent: "start",
          alignItems: "stretch",
          padding:
            "0% 27.287066246056785% 1.6824395373291272% 27.287066246056785%",
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 486",
          }}
        >
          <Suspense>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0%",
                aspectRatio: "864 / 486",
                justifyContent: "start",
                alignItems: "stretch",
                padding: "0% 0% 0% 0%",
                backgroundImage:
                  "url('https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt82ca8ad16ef161c3/65580ef7d6b2f53c02534c7b/Val_Ep7_ArticleAsset_es_MX.jpg')",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Suspense>
        </div>
      </div>
    </div>,
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1902 / 190",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16.842105263157894%",
          aspectRatio: "1902 / 190",
          justifyContent: "start",
          alignItems: "stretch",
          padding:
            "0% 27.287066246056785% 1.6824395373291272% 27.287066246056785%",
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.171875px",
            lineHeight: "28px",
            fontWeight: "700",
          }}
        >
          Consigue aprobación en DD (27 de noviembre – 4 de diciembre)
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.171875px",
            lineHeight: "28px",
          }}
        >
          Objetivo de la semana uno: como comunidad, juega al menos 555 555
          partidas de Deathmatch Definitivo
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.171875px",
            lineHeight: "28px",
          }}
        >
          Recompensa: Gun Buddy Sello de aprobación
        </div>
      </div>
    </div>,
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1901 / 98",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5260389268805892%",
          aspectRatio: "1901 / 98",
          justifyContent: "start",
          alignItems: "stretch",
          padding: "0% 27.301420305102575% 0% 27.301420305102575%",
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "863 / 98",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10.204081632653061%",
              aspectRatio: "863 / 98",
              justifyContent: "center",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "863 / 2",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "863 / 2",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                  backgroundColor: "#BCB9B4",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1902 / 873.5",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0%",
          aspectRatio: "1902 / 873.5",
          justifyContent: "end",
          alignItems: "stretch",
          padding:
            "2.5236593059936907% 27.287066246056785% 2.5236593059936907% 27.287066246056785%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "820.3699951171875 / 150",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "22.666666666666664%",
              aspectRatio: "820.3699951171875 / 150",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "820.3699951171875 / 88",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "36.36363636363637%",
                  aspectRatio: "820.3699951171875 / 88",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    fontSize: "16.3125px",
                    lineHeight: "28px",
                    fontWeight: "700",
                  }}
                >
                  Preguntas Frecuentes
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    fontSize: "15.890625px",
                    lineHeight: "28px",
                    fontWeight: "700",
                  }}
                >
                  ¿Cómo puedo participar?
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "16.3125px",
                lineHeight: "28px",
              }}
            >
              Inicia sesión durante las fechas mencionadas anteriormente y
              contribuye con cada objetivo, así de simple.
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 91",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "864 / 91",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 178",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "17.97752808988764%",
              aspectRatio: "864 / 178",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "864 / 90",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "37.77777777777778%",
                  aspectRatio: "864 / 90",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    fontSize: "16.171875px",
                    lineHeight: "28px",
                    fontWeight: "700",
                  }}
                >
                  ¿Dónde veo el progreso?
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    fontSize: "16.3125px",
                    lineHeight: "28px",
                  }}
                >
                  El progreso se compartirá a través de las redes sociales de
                  VALORANT. Asegúrate de seguirnos:
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "864 / 56",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "864 / 56",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 3.7037037037037033% 0% 3.7037037037037033%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "800 / 28",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0%",
                      aspectRatio: "800 / 28",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 93.57250213623047% 0% 0%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        fontSize: "14.5px",
                        lineHeight: "28px",
                      }}
                    >
                      Twitter
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    aspectRatio: "800 / 28",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0%",
                      aspectRatio: "800 / 28",
                      justifyContent: "start",
                      alignItems: "stretch",
                      padding: "0% 90.90499877929688% 0% 0%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        fontSize: "14.625px",
                        lineHeight: "28px",
                      }}
                    >
                      Instagram
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 59",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "864 / 59",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 90",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "37.77777777777778%",
              aspectRatio: "864 / 90",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "16.171875px",
                lineHeight: "28px",
                fontWeight: "700",
              }}
            >
              ¿Dónde canjeo los códigos para las recompensas?
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "16.453125px",
                lineHeight: "28px",
              }}
            >
              Inicia sesión y canjea tus códigos en&nbsp;
              <span
                style={{
                  fontWeight: "700",
                  textDecoration: "underline",
                }}
              >
                https://shop.riotgames.com/es-mx/redeem/
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 31",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "864 / 31",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 57",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.7543859649122806%",
              aspectRatio: "864 / 57",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "16.3125px",
                lineHeight: "28px",
                fontWeight: "700",
              }}
            >
              NOTA IMPORTANTE: los códigos de las recompensas expiran el 31 de
              diciembre
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                fontSize: "16.3125px",
                lineHeight: "28px",
                fontWeight: "700",
              }}
            >
              a las 11:59 PM hora del Pacífico. ¡Asegúrate de canjearlos antes
              de Año Nuevo!
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "864 / 93.5",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "864 / 93.5",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "16.3125px",
            lineHeight: "28px",
            whiteSpace: "nowrap",
          }}
        >
          Muchas gracias por este año maravilloso. ¡Diviértete mucho!
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            fontSize: "18px",
            lineHeight: "28px",
          }}
        ></div>
      </div>
    </div>,
    <R76LogoCarpet />,
    <R76Footer />,
  ]);

  const homeElement = ({ index, style }) => {
    return (
      <div className="inter-400" key={"read-ele-" + index} style={{ ...style }}>
        {homeElements[index]}
      </div>
    );
  };

  //   return (
  //     <Fragment>
  //       <List
  //         height={window.innerHeight}
  //         itemCount={sizes.home.length}
  //         itemSize={(index) => getItemSize(index, windowWidth)} // Pass the function to calculate item size
  //         width={window.innerWidth}
  //       >
  //         {homeElement}
  //       </List>
  //     </Fragment>
  //   );
  return (
    <div
      className="home-page"
      style={{ backgroundColor: "#ECE8E1", opacity: 0 }}
    >
      <List
        height={window.innerHeight}
        itemCount={sizes.home.length}
        itemSize={(index) => getItemSize(index, windowWidth)} // Pass the function to calculate item size
        width={window.innerWidth}
      >
        {homeElement}
      </List>
    </div>
  );
}
