import logoBelt from "../Assets/logobelt.png";

export default function R76LogoCarpet() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        aspectRatio: "2048.080078125 / 99.5199966430664",
        backgroundColor: '#D72A47'
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.4882621586337051%",
          aspectRatio: "2048.080078125 / 99.5199966430664",
          justifyContent: "center",
          alignItems: "stretch",
          padding: "0.3281121603577021% 0% 0.3281121603577021% 0%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            aspectRatio: "1284 / 86.08000183105469",
            minHeight: "100%",
            backgroundImage: "url('" + logoBelt + "')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0%",
              aspectRatio: "1284 / 86.08000183105469",
              justifyContent: "start",
              alignItems: "stretch",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "1284 / 86.08000183105469",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "1284 / 86.08000183105469",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                aspectRatio: "1284 / 86.08000183105469",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0%",
                  aspectRatio: "1284 / 86.08000183105469",
                  justifyContent: "start",
                  alignItems: "stretch",
                  padding: "0% 0% 0% 0%",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
